import React, { useEffect, useState } from "react";
import Logo from "../assets/Logo.png";
import { backendUrl } from "../App";
import axios from "axios";
import { HoverBorderGradient } from "../components/hover-border-gradient";
import { Button } from "../components/moving-border";
// import LinkedIn icon
import { IconBrandLinkedin } from "@tabler/icons-react";
import { IconBrandInstagram } from "@tabler/icons-react";

function Home() {
  const [email, setEmail] = useState("");
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(false);
  const registerUser = async (e) => {
    setLoading(true);
    e.preventDefault();
    // checking with regex if the email is valid
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address");
      return;
    }
    try {
      await axios
        .post(`${backendUrl}/api/register`, {
          email,
        })
        .then((res) => {
          localStorage.setItem("registered", true);
          setRegistered(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("registered")) {
      setRegistered(true);
    }
  }, []);

  return (
    <div className="h-[100vh] flex-col w-full bg-black  bg-grid-white/[0.2] relative flex items-center justify-center">
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      <p className="text-4xl sm:text-7xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 py-8">
        <img
          src={Logo}
          alt="Skaap Logo"
          className="w-[150px] h-[150px] inline-block"
        />
      </p>
      {registered ? (
        <p className="text-lg text-neutral-300">
          You have successfully registered!
        </p>
      ) : (
        // <div className="gap-2 flex flex-wrap items-start justify-center">
        <form
          onSubmit={registerUser}
          className="gap-2 flex flex-wrap items-start justify-center"
        >
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Enter your email for early access"
            className="w-[360px] h-12 px-4 py-2 text-lg border border-neutral-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-neutral-500 focus:border-transparent"
          />
          <Button disabled={loading} onClick={registerUser}>
            Join
          </Button>
        </form>
        // </div>
      )}
      <div className="mt-10">
        <a
          href="https://www.linkedin.com/company/skaaptech/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2 text-neutral-300 hover:text-neutral-500"
        >
          <IconBrandLinkedin size={24} />
          <span>Follow us on LinkedIn</span>
        </a>
      </div>
      <div className="mt-2">
        <a
          href="https://www.instagram.com/skaapinc/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center gap-2 text-neutral-300 hover:text-neutral-500"
        >
          <IconBrandInstagram size={24} />
          <span>Follow us on Instagram</span>
        </a>
      </div>
    </div>
  );
}

export default Home;
