import logo from "./logo.svg";
import "./App.css";
import Routers from "./Routers";
import { useContext, createContext, useState } from "react";
import { Analytics } from "@vercel/analytics/react"

export const UserContext = createContext();
export const backendUrl ="https://skaap-tech-waitlist-9k0m.onrender.com";
function App() {
  const [user, setUser] = useState(null);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Routers />
      <Analytics />
    </UserContext.Provider>
  );
}

export default App;
